import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import styles from "./enterpriseCard.module.css";

export default function EnterpriseCard({ handleClick }) {
  const { t } = useTranslation();
  return (
    <div className={styles.enterpriseCard}>
      <div className={styles.enterpriseCardContext}>
        <div className={styles.textBox}>
          <p className={styles.titleCard}>{t("enterprise")}</p>
          <p className={styles.priceCard}>{t("onRequest")}</p>
          <p className={styles.descriptionCard}>{t("enterprisePlanDescription")}</p>

          <ul className={styles.featuresList}>
            <li>{t("enterpriseFeature1")}</li>
            <li>{t("enterpriseFeature2")}</li>
            <li>{t("enterpriseFeature3")}</li>
            <li>{t("enterpriseFeature4")}</li>
            <li>{t("enterpriseFeature5")}</li>
          </ul>
        </div>

        <button
          className={`${styles.buttonOpenAccount} ${styles.enterpriseBtn}`}
          type="button"
          id="contactSalesButton"
          onClick={() => handleClick("/contactUs")}
        >
          <div className={styles.parentComponent}>
            <span>{t("contactSales")}</span>
            <img src="/arrow-right-orange.webp" alt="arrow-right" />
          </div>
        </button>
      </div>

      <div className={styles.enterpriseImgBox}>
        <img className={styles.enterpriseImg} src="/IllustrationEnterprise.webp" alt="enterprise" />
      </div>
    </div>
  );
}

EnterpriseCard.propTypes = {
  handleClick: PropTypes.func.isRequired,
};
