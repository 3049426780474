import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import styles from "./pricing.module.css";
import { selectAuth } from "../../../../features/auth/selectors";
import usePricing from "../../../../hooks/usePricing";

export default function Pricing() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { currency, pricingDetails, isMonthOrYear, setIsMonthOrYear, isLoading } = usePricing();
  const auth = useSelector(selectAuth);
  const { isAuth } = auth;

  const handleClick = (path) => navigate(path);
  const handleClickAuth = () => (isAuth ? navigate("/account/mySynthetic") : navigate("/signUp"));

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div id="price" className={styles.pricingBox}>
      <div className={styles.contextBox}>
        {/* FREE PLAN */}
        <div className={styles.freeCard}>
          <div className={styles.freeCardContext}>
            <div className={styles.textBox}>
              <p className={styles.titleCard}>{t("starter")}</p>
              <p className={styles.priceCard}>{t("free")}</p>
              <p className={styles.descriptionCard}>{t("seeHowItWork")}</p>
            </div>
            <button
              className={`${styles.buttonOpenAccount} ${styles.freeBtn}`}
              type="button"
              id="chooseFreePlanButton"
              onClick={handleClickAuth}
            >
              <div className={styles.parentComponent}>
                <span>{t("chooseFree")}</span>
                <img src="/arrow-right-black.webp" alt="arrow-right" />
              </div>
            </button>
          </div>
          <div className={styles.starter}>
            <p className={styles.monthlyPriceText}>{t("createAIAssistant")}</p>
            <p className={styles.sepLineFree} />
            <p className={styles.description1Card}>
              <img src="/circle-check-grau.webp" alt="circle-check" width="25" height="25" />
              <span>{t("domainsToConnect")}</span>
            </p>
            <p className={styles.description1Card}>
              <img src="/circle-check-grau.webp" alt="circle-check" width="25" height="25" />
              <span>{t("pagesForOneDomain")}</span>
            </p>
            <p className={styles.description1Card}>
              <img src="/circle-check-grau.webp" alt="circle-check" width="25" height="25" />
              <span>
                {pricingDetails.free?.min} {t("generationTime")}
              </span>
            </p>
          </div>
        </div>

        {/* SWITCH MONTH/YEAR */}
        <div className={styles.buttonBox}>
          <button
            className={`${styles.monthButton} ${isMonthOrYear === "month" ? styles.activeBtn : ""}`}
            type="button"
            onClick={() => setIsMonthOrYear("month")}
            id="monthlyPricingButton"
          >
            {t("monthly")}
          </button>
          <button
            className={`${styles.yearButton} ${isMonthOrYear === "year" ? styles.activeBtn : ""}`}
            type="button"
            onClick={() => setIsMonthOrYear("year")}
            id="yearlyPricingButton"
          >
            {t("yearly")}
          </button>
        </div>

        {/* BASIC PLAN */}
        <div className={styles.basicCard}>
          <div className={styles.basicCardContext}>
            <div className={styles.textBox}>
              <div className={styles.giftBox}>
                <p className={styles.titleCard}>{t("basic")}</p>
                {isMonthOrYear === "year" && (
                  <div className={styles.discountLabel}>
                    <span className={styles.discountText}>-{pricingDetails.basic?.discount}%</span>
                  </div>
                )}
              </div>
              <div className={`${styles.priceCardBox} ${isMonthOrYear === "year" ? styles.overlap : ""}`}>
                {isMonthOrYear === "year" && (
                  <>
                    <span className={styles.newAnnualPrice}>{pricingDetails.basic?.discountedPrice}</span>

                    <span className={styles.descPrice}>{t("perMonth")}</span>

                    <div className={styles.monthlyPriceText}>{t("discountedMonthlyPrice")}</div>
                  </>
                )}
                {isMonthOrYear === "month" && (
                  <>
                    <span className={styles.price}>{pricingDetails.basic?.price}</span>
                    <span className={styles.descPrice}>{t("perMonth")}</span>
                  </>
                )}
              </div>

              <p className={styles.sepLine} />
              <p className={styles.description1Card}>
                <img src="/circle-check-blau.webp" alt="circle-check" />
                <span>
                  {pricingDetails.basic?.domains} {t("domainsToConnect")}
                </span>
              </p>
              <p className={styles.description2Card}>
                <img src="/circle-check-blau.webp" alt="circle-check" />
                <span>
                  {pricingDetails.basic?.pages} {t("pagesForOneDomain")}
                </span>
              </p>
              <p className={styles.description3Card}>
                <img src="/circle-check-blau.webp" alt="circle-check" />
                <span>
                  {pricingDetails.basic?.min} {t("generationTime")}
                </span>
              </p>
            </div>
            <button
              className={`${styles.buttonOpenAccount} ${styles.basicBtn}`}
              type="button"
              id="chooseBasicPlanButton"
              onClick={() => handleClick("/pricing")}
            >
              <div className={styles.parentComponent}>
                <span>{t("chooseBasic")}</span>
                <img src="/arrow-right-blue.webp" alt="arrow-right" />
              </div>
            </button>
          </div>
        </div>

        {/* PRO PLAN */}
        <div className={styles.proCard}>
          <div className={styles.proCardContext}>
            <div className={styles.textBox}>
              <div className={styles.giftBox}>
                <p className={styles.titleCard}>Pro</p>
                {isMonthOrYear === "year" && (
                  <div className={styles.discountLabel}>
                    <span className={styles.discountText}>-{pricingDetails.pro?.discount}%</span>
                  </div>
                )}
              </div>

              <div className={`${styles.priceCardBox} ${isMonthOrYear === "year" ? styles.overlap : ""}`}>
                {isMonthOrYear === "year" && (
                  <>
                    <span className={styles.newAnnualPrice}>{pricingDetails.pro?.discountedPrice}</span>

                    <span className={styles.descPrice}>{t("perMonth")}</span>

                    <div className={styles.monthlyPriceText}>{t("discountedMonthlyPrice")}</div>
                  </>
                )}

                {isMonthOrYear === "month" && (
                  <>
                    <span className={styles.price}>{pricingDetails.pro?.price}</span>
                    <span className={styles.descPrice}>{t("perMonth")}</span>
                  </>
                )}
              </div>
              <p className={styles.sepLine} />
              <p className={styles.description1Card}>
                <img src="/circle-check-lilla.webp" alt="circle-check" />
                <span>
                  {pricingDetails.pro?.domains} {t("domainsToConnect")}
                </span>
              </p>
              <p className={styles.description2Card}>
                <img src="/circle-check-lilla.webp" alt="circle-check" />
                <span>
                  {pricingDetails.pro?.pages} {t("pagesForOneDomain")}
                </span>
              </p>
              <p className={styles.description3Card}>
                <img src="/circle-check-lilla.webp" alt="circle-check" />
                <span>
                  {pricingDetails.pro?.min} {t("generationTime")}
                </span>
              </p>
            </div>
            <button
              className={`${styles.buttonOpenAccount} ${styles.proBtn}`}
              type="button"
              id="chooseProPlanButton"
              onClick={() => handleClick("/pricing")}
            >
              <div className={styles.parentComponent}>
                <span>{t("chooseAdvanced")}</span>
                <img src="/arrow-right-lilla.webp" alt="arrow-right" />
              </div>
            </button>
          </div>
        </div>

        {/* ENTERPRISE PLAN */}
        <div className={styles.enterpriseCard}>
          <div className={styles.enterpriseCardContext}>
            <div className={styles.textBox}>
              <p className={styles.titleCard}>{t("enterprise")}</p>
              <p className={styles.priceCard}>{t("onRequest")}</p>
              <p className={styles.descriptionCard}>{t("customBusinessSupport")}</p>
            </div>
            <button
              className={`${styles.buttonOpenAccount} ${styles.enterpriseBtn}`}
              type="button"
              id="contactSalesButton"
              onClick={() => handleClick("/contactUs")}
            >
              <div className={styles.parentComponent}>
                <span>{t("contactSales")}</span>
                <img src="/arrow-right-orange.webp" alt="arrow-right" />
              </div>
            </button>
          </div>
          <div className={styles.enterpriseImgBox}>
            <img className={styles.enterpriseImg} src="/IllustrationEnterprise.webp" alt="enterprise" />
          </div>
        </div>
      </div>
    </div>
  );
}
