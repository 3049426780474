import { useState, useEffect, useCallback, useRef } from "react";
import { useDispatch } from "react-redux";
import { getPricing, getPricingConfig } from "../features/price_request/priceSlice";

const usePricing = () => {
  const dispatch = useDispatch();
  const [currency, setCurrency] = useState(localStorage.getItem("currency") || "EUR");
  const [exchangeRate, setExchangeRate] = useState(1);
  const [pricingDetails, setPricingDetails] = useState({});
  const [isMonthOrYear, setIsMonthOrYear] = useState("year");

  const hasFetchedCurrency = useRef(false);

  const getUserCurrency = async () => {
    const storedIp = localStorage.getItem("user_ip");
    if (storedIp) {
      return;
    }

    try {
      const response = await fetch("https://ipapi.co/json/");
      if (!response.ok) throw new Error("Failed to fetch IP");

      const data = await response.json();
      const detectedCurrency = data.country_code === "US" ? "USD" : "EUR";

      localStorage.setItem("user_ip", data.ip);
      localStorage.setItem("currency", detectedCurrency);
      setCurrency(detectedCurrency);
    } catch (error) {
      console.error("Error fetching user currency:", error);
      setCurrency("EUR");
    }
  };

  const fetchExchangeRate = useCallback(async () => {
    if (currency === "EUR") {
      setExchangeRate(1);
      return;
    }

    try {
      const response = await fetch("https://api.exchangerate-api.com/v4/latest/EUR");
      if (!response.ok) throw new Error("Failed to fetch exchange rate");

      const data = await response.json();
      setExchangeRate(data.rates.USD || 1);
    } catch (error) {
      console.error("Error fetching exchange rate:", error);
      setExchangeRate(1);
    }
  }, [currency]);

  const formatCurrency = useCallback(
    (amount) => {
      return new Intl.NumberFormat("en-US", {
        style: "currency",
        currency,
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      }).format(amount);
    },
    [currency]
  );

  const calculateDiscountedPrice = (price, discount) => {
    return Math.round(price * (1 - discount * 0.01));
  };

  const fetchPricingDetails = useCallback(async () => {
    try {
      const pricing = await dispatch(getPricing()).unwrap();
      const pricingConf = await dispatch(getPricingConfig()).unwrap();
      console.log("pricing", pricing);
      console.log("pricingConf", pricingConf);
      const convertPrice = (eurPrice) => (currency === "USD" ? Math.round(eurPrice * exchangeRate) : eurPrice);

      setPricingDetails({
        free: {
          price: formatCurrency(convertPrice(pricing[0]?.monthlyPrice || 0)),
          domains: pricingConf[0]?.domainsToConnect || 0,
          pages: pricingConf[0]?.pagesPerDomain || 0,
          min: Math.round((pricingConf[0]?.coinsLimitPerMonth * 15) / 60),
          avatar: pricingConf[0]?.avatarsIncluded || 1,
          licence: pricingConf[0]?.licence || "Personal",
          watermark: pricingConf[0]?.watermark || "Full aria",
          support: pricingConf[0]?.support,
          generator: pricingConf[0]?.generator || "AI",
          customVideo: pricingConf[0]?.customVideo || "Available",
        },
        basic: {
          price: formatCurrency(convertPrice(pricing[1]?.monthlyPrice || 0)),
          discount: pricing[1]?.discount || 0,
          discountedPrice: formatCurrency(
            convertPrice(calculateDiscountedPrice(pricing[1]?.monthlyPrice || 0, pricing[1]?.discount || 0))
          ),
          domains: pricingConf[1]?.domainsToConnect || 0,
          pages: pricingConf[1]?.pagesPerDomain || 0,
          min: Math.round((pricingConf[1]?.coinsLimitPerMonth * 15) / 60),
          avatar: pricingConf[1]?.avatarsIncluded || 5,
          extraVideo: pricingConf[1]?.extraPricePerMinute || 5,
          licence: pricingConf[1]?.licence || "Commercial",
          watermark: pricingConf[1]?.watermark || "Synthetic",
          support: pricingConf[1]?.support,
        },
        pro: {
          price: formatCurrency(convertPrice(pricing[2]?.monthlyPrice || 0)),
          discount: pricing[2]?.discount || 0,
          discountedPrice: formatCurrency(
            convertPrice(calculateDiscountedPrice(pricing[2]?.monthlyPrice || 0, pricing[2]?.discount || 0))
          ),
          domains: pricingConf[2]?.domainsToConnect || 0,
          pages: pricingConf[2]?.pagesPerDomain || 0,
          min: Math.round((pricingConf[2]?.coinsLimitPerMonth * 15) / 60),
          avatar: pricingConf[2]?.avatarsIncluded || 50,
          extraVideo: pricingConf[2]?.extraPricePerMinute || 3.5,
          licence: pricingConf[2]?.licence || "Commercial",
          watermark: pricingConf[2]?.watermark || "No",
          support: pricingConf[2]?.support,
          generator: pricingConf[2]?.generator || "AI",
          customVideo: pricingConf[2]?.customVideo || "Available",
        },
      });
    } catch (error) {
      console.error("Error fetching pricing details:", error);
    }
  }, [dispatch, currency, exchangeRate, formatCurrency]);

  useEffect(() => {
    if (!hasFetchedCurrency.current) {
      getUserCurrency();
      hasFetchedCurrency.current = true;
    }
  }, []);

  useEffect(() => {
    fetchExchangeRate();
  }, [fetchExchangeRate]);

  useEffect(() => {
    if (currency && exchangeRate) {
      fetchPricingDetails();
    }
  }, [currency, exchangeRate, fetchPricingDetails]);

  return {
    currency,
    pricingDetails,
    isMonthOrYear,
    setIsMonthOrYear,
  };
};

export default usePricing;
