import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import usePricing from "../../hooks/usePricing";
import PricingCard from "./PricingCard";
import styles from "./pricePage.module.css";
import EnterpriseCard from "./EnterpriseCard";

export default function PricePageMultiCurrency() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { currency, pricingDetails, isMonthOrYear, setIsMonthOrYear, isLoading } = usePricing();

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div className={styles.pricingBox}>
      <h1 className={styles.title}>{t("pricingTitle")}</h1>
      <div className={styles.buttonBox}>
        <button
          className={`${styles.monthButton} ${isMonthOrYear === "month" ? styles.activeBtn : ""}`}
          onClick={() => setIsMonthOrYear("month")}
        >
          {t("monthly")}
        </button>
        <button
          className={`${styles.yearButton} ${isMonthOrYear === "year" ? styles.activeBtn : ""}`}
          onClick={() => setIsMonthOrYear("year")}
        >
          {t("yearly")}
        </button>
      </div>
      <div className={styles.cardWrapper}>
        <div className={styles.cardContainer}>
          {/* Free Plan */}
          <PricingCard
            title={t("starter")}
            price={pricingDetails.free?.price || t(2)}
            features={[
              `${t("unlimited")} ${t("domainsToConnect")}`,
              `${t("unlimited")} ${t("pagesForOneDomain")}`,
              `${pricingDetails.free?.min} ${t("generationTime")}`,
              `${pricingDetails.free?.avatar} ${t("avatar")}`,
              `${pricingDetails.free?.licence} ${t("licence")}`,
              `${pricingDetails.free?.watermark} ${t("watermark")}`,
              `${pricingDetails.free?.support} ${t("support")}`,
              `${pricingDetails.free?.generator} ${t("generator")}`,
              `${pricingDetails.free?.customVideo} ${t("customVideo")}`,
            ]}
            buttonText={t("chooseFree")}
            buttonAction={() => navigate("/signUp")}
            isProTariff={false}
            isRecurringPrice={false}
          />

          {/* Basic Plan */}
          <PricingCard
            title={t("basic")}
            price={isMonthOrYear === "year" ? pricingDetails.basic?.discountedPrice : pricingDetails.basic?.price}
            features={[
              `${pricingDetails.basic?.domains} ${t("domainsToConnect")}`,
              `${pricingDetails.basic?.pages} ${t("pagesForOneDomain")}`,
              `${pricingDetails.basic?.min} ${t("generationTime")}`,
              `${pricingDetails.basic?.extraVideo} ${t("extraVideo")}`,
              `${pricingDetails.basic?.avatar} ${t("avatar")}`,
              `${pricingDetails.basic?.licence} ${t("licence")}`,
              `${pricingDetails.basic?.watermark} ${t("watermark")}`,
              `${pricingDetails.basic?.support} ${t("support")}`,
            ]}
            buttonText={t("chooseBasic")}
            buttonAction={() => navigate("/pricing")}
            isProTariff={false}
            isRecurringPrice={isMonthOrYear === "year"}
          />

          {/* Pro Plan */}
          <PricingCard
            title="Pro"
            price={isMonthOrYear === "year" ? pricingDetails.pro?.discountedPrice : pricingDetails.pro?.price}
            features={[
              `${pricingDetails.pro?.domains} ${t("domainsToConnect")}`,
              `${pricingDetails.pro?.pages} ${t("pagesForOneDomain")}`,
              `${pricingDetails.pro?.min} ${t("generationTime")}`,
              `${pricingDetails.pro?.extraVideo} ${t("extraVideo")}`,
              `${pricingDetails.pro?.avatar} ${t("avatar")}`,
              `${pricingDetails.pro?.licence} ${t("licence")}`,
              `${pricingDetails.pro?.watermark} ${t("watermark")}`,
              `${pricingDetails.pro?.support} ${t("support")}`,
              `${pricingDetails.pro?.generator} ${t("generator")}`,
              `${pricingDetails.pro?.customVideo} ${t("customVideo")}`,
            ]}
            buttonText={t("chooseAdvanced")}
            buttonAction={() => navigate("/pricing")}
            isProTariff
            isRecurringPrice={isMonthOrYear === "year"}
          />
        </div>
      </div>
      <EnterpriseCard handleClick={navigate} />
    </div>
  );
}
