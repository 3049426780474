import { axiosDefault } from "../auth/service/axios.config";

export async function getAllPricing() {
  const res = await axiosDefault.get("/api/pricing");
  if (res.status !== 200) {
    throw res.data;
  }
  return res.data;
}

export async function getAllPricingConfig() {
  const res = await axiosDefault.get("/api/pricingConfig/all");
  if (res.status !== 200) {
    throw res.data;
  }
  return res.data;
}
